<template>
  <div class="home-all">

    <div class="overall">
      <div class="ovearall_title">资源统计</div>
      <div class="card_container">
        <div>
          <home-card
            :colorleft="'#4edbfc'"
            :colorright="'#0298cc'"
            :colorreflection="'#c8f1fb'"
            :icon="'icon_attractions'"
            :code="'play'"
            :info="{ number: numO.play, text: '景点数量' }"
          ></home-card>
        </div>
        <div>
          <home-card
            :colorleft="'#ae95ff'"
            :colorright="'#753ccb'"
            :colorreflection="'#e0d4f9'"
            :icon="'icon_food'"
            :code="'restaurant'"
            :info="{ number: numO.restaurant, text: '餐馆饭店' }"
          ></home-card>
        </div>
        <div>
          <home-card
            :colorleft="'#ead059'"
            :colorright="'#fd8127'"
            :colorreflection="'#fae6be'"
            :icon="'icon_stay'"
            :code="'stay'"
            :info="{ number: numO.stay, text: '住宿数量' }"
          ></home-card>
        </div>

        <div>
          <div>
            <home-card
              :colorleft="'#43b6ff'"
              :colorright="'#0168ff'"
              :colorreflection="'#c3e6ff'"
              :icon="'icon_amusement'"
              :code="'amuse'"
              :info="{ number: numO.amuse, text: '娱乐数量' }"
            ></home-card>
          </div>
        </div>
      </div>
      <div class="card_container">

        <div>
          <home-card
            :colorleft="'#43b6ff'"
            :colorright="'#0168ff'"
            :colorreflection="'#c3e6ff'"
            :icon="'icon_line'"
            :code="'travel_line'"
            :info="{ number: numO.travel_line, text: '线路数量' }"
          ></home-card>
        </div>
        <div>
          <home-card
            :colorleft="'#4edbfc'"
            :colorright="'#0298cc'"
            :colorreflection="'#c8f1fb'"
            :icon="'icon_ticket'"
            :code="'ticket'"
            :info="{ number: 23, text: '门票数量' }"
          ></home-card>
        </div>

<!--
        厕所数量、停车场数量、监控数量、智慧厕所、慢直播数量 -->


        <div>
          <home-card
          :colorleft="'#43b6ff'"
            :colorright="'#0168ff'"
            :colorreflection="'#c3e6ff'"
            :icon="'icon_line'"
            :code="'toilet'"
            :info="{ number: numO.toilet, text: '厕所数量' }"
          ></home-card>
        </div>


        <div>
          <home-card
          :colorleft="'#ead059'"
            :colorright="'#fd8127'"
            :colorreflection="'#fae6be'"
            :icon="'icon_stay'"
            :code="'carpark'"
            :info="{ number: numO.carpark, text: '停车场数量' }"
          ></home-card>
        </div>

      </div>
<!--厕所数量、停车场数量、监控数量、智慧厕所、慢直播数量 旅游项目 -->
      <div class="card_container">
        <div>
          <home-card
            :colorleft="'#4edbfc'"
            :colorright="'#0298cc'"
            :colorreflection="'#c8f1fb'"
            :icon="'icon_attractions'"
            link="http://114.98.87.76:30025/?login_page=yaq"
            :info="{ number: 140, text: '监控数量' }"
          ></home-card>
        </div>
        <div>
          <home-card
          :colorleft="'#ead059'"
            :colorright="'#fd8127'"
            :colorreflection="'#fae6be'"
            :icon="'icon_stay'"
            link="http://114.98.87.76:30011/#/deploy?pid=yaq"
            :info="{ number: 1, text: '智慧厕所数量' }"
          ></home-card>
        </div>
        <div>
          <home-card
            :colorleft="'#4edbfc'"
            :colorright="'#0298cc'"
            :colorreflection="'#c8f1fb'"
            :icon="'icon_attractions'"
            link="http://114.98.87.76:30025/?login_page=yaq"
            :info="{ number: 3, text: '慢直播数量' }"
          ></home-card>
        </div>
        <div>
          <home-card
            :colorleft="'#4edbfc'"
            :colorright="'#0298cc'"
            :colorreflection="'#c8f1fb'"
            :icon="'icon_attractions'"
            link="http://114.98.87.76:30011/#/deploy?pid=yaq"
            :info="{ number:33, text: '旅游项目' }"
          ></home-card>
        </div>
      </div>














    </div>
  </div>
</template>

<script>
import HomeCard from "@components/content/homecard/HomeCard";
export default {
  data() {
    return {
      numberCodeArr: [
        "member",
        "comment",
        "consultation",
        "complaint_case",
        "play",
        "food",
        "stay",
        "amuse",
        "pro_owner",
        "commodity",
        "travel_line",
        "ticket",
        "restaurant",
        "carpark",
        "toilet",
      ],
      numO: {},
    };
  },
  components: {
    HomeCard,
  },
  created() {
    this.getHomeNumberAll();
    // console.log(this.numO)
  },
  methods: {
    getHomeNumber(code) {
      let params = {
        // data: { is_check: 1 },
         directory_code: code };
      this.$Request({
        method: "get",
        url: this.$Api.activeStatisticsdata,
        // headers: {'Content-Type': 'application/json'},
        params,
      })
        .then((result) => {
          // this.numO[code]=result.data.list[0].countNums
          console.log(result.data.list[0].countNums)
          this.$set(this.numO, code, result.data.list[0].countNums);
        })
        .catch((err) => {
          console.log('失败调用')
        });
    },
    getHomeNumberAll() {
      let t = 0;
      for (const item of this.numberCodeArr) {
        t++;
        setTimeout(() => {
          this.getHomeNumber(item);
        }, 10 * t);
      }
    },
    // jumpToPage(index,name,currentIndex){
    //   console.log(currentIndex) //成功获取到对应的父级
    //   this.$store.commit("setcurrentNav", { index, name });
    //   this.$router.push({ name: "modelmanage" });

    // }
  },
};
</script>

<style lang="scss" scoped>
.home-all {
  height: 100%;
  overflow-y: scroll;
  // overflow-x: hidden;
}
.el-row {
  margin-bottom: 20px;
}
.overall {
  background-color: #fff;
  border-radius: 5px;
  padding-bottom: 38px;
  padding-left: 30px;
  // margin-top: 20px;
  border: 1px solid #f0eeee;
  box-shadow: 0px 0px 10px #d8d5d5;
}

.home-all .overall:nth-child(2){
  margin-top: 20px;
}

.ovearall_title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 1px;
  color: #595757;
  padding-top: 30px;
  padding-bottom: 24px;
}
.card_container {
  padding-bottom: 50px;
  display: flex;
  div {
    margin-right: 25px;
    width: 17vw;
  }
}

/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
}
/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 0.5px;
  //padding-left: 10px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #fafbfc;
  border-radius: 3px;
}
</style>