<template>
  <div
    :style="{
      background:
        'linear-gradient(to right, ' + colorleft + ', ' + colorright + ')',
    }"
    class="card"
  >
    <div class="left">
      <div :style="{ backgroundImage: 'url(' + img + ')' }" class="card_icon"></div>
    </div>
    <div class="right">
      <a :href="link" target="_blank" v-if="link" style="color: #ffffff;">
        <div class="jumpToPage" @click="jumpToPage">
        <!-- {{ !info.number ? 0 : info.number }} -->
        <count-to :startVal="0" :endVal="!info.number ? 0 : info.number" :duration="1000"></count-to>
      </div>
      </a>
      <div class="jumpToPage" @click="jumpToPage" v-else>
        <!-- {{ !info.number ? 0 : info.number }} -->
        <count-to :startVal="0" :endVal="!info.number ? 0 : info.number" :duration="1000"></count-to>
      </div>

      <div>{{ info.text }}</div>
    </div>
    <div
      class="card_reflection"
      :style="{
        background: 'linear-gradient(' + colorreflection + ', #ffffff)',
      }"
    ></div>
  </div>
</template>

<script>
//count to 插件
import CountTo from "vue-count-to";
import { leftNavData } from "../../common/leftnavbar/conf/leftNavData";
export default {
  components: {
    CountTo,
  },
  props: ["colorleft", "colorright", "colorreflection", "icon", "info","code","link"],
  watch: {
    // info(val){
    //   this.info.number=val
    //   console.log(val);
    // }
  },
  data() {
    return {
      img: require("@assets/images/" + this.icon + ".png"),
      leftNavData,
      currentIndex:"",
      currentPosition:0
    }
  },
  beforeDestroy() {
    // this.$store.commit("setCurrentParnetIndex", null);
    // this.$store.commit("setCurrentPosition", null);
  },
  // watch: {
  //   leftNavData(val){
  //     console.log('数据发生了改变')
  //   }
  // },
  methods:{

    jumpToPage(){
      let index = this.code;
      console.log(index,'index');
      if (!index) return
      this.calcParentIndex(index)
      this.calcCurrentPosition(index)
      // this.$store.commit("setcurrentNav", { index, name });
      this.$bus.$emit("jumpToPage");
      this.$router.push({ name: "modelmanage" });
    },

    //计算当前选中卡片的父级index
    calcParentIndex(index){
      this.leftNavData.forEach(item =>{
        item.childe?.forEach(val =>{
          if(val.index == index){
            this.currentIndex = item.index
            this.$store.commit('setCurrentParnetIndex',this.currentIndex)
          }
        })
      })
    },
    //获取到当前选中卡片在数组中的位置
    calcCurrentPosition(index){
      const indexs = this.leftNavData.findIndex(item => item.index === this.currentIndex);
      this.currentPosition = this.leftNavData[indexs]?.childe.findIndex(child => child.index === index);
      this.$store.commit('setCurrentPosition',this.currentPosition)
    }

  }

};
</script>

<style lang="scss" scoped>
.card {
  height: 150px;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  position: relative;
  .card_reflection {
    height: 30px;
    width: 100%;
    border-radius: 8px;
    position: absolute;
    bottom: -30px;
  }
  .left {
    float: left;
    width: 50%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 50%;
    height: 150px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-right: 10px;
    font-size: 36px;
    cursor: pointer;
    div:nth-child(2) {
      font-size: 15px;
    }
  }
  .card_icon {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>